import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Tooltip, Chip } from "@mui/material";
import { fetchSAQFactories, getListOfAllFactories } from "../../reducers/factory.reducer";
import { getListOfAllSuppliers } from "../../reducers/supplier.reducer";
import { selectSAQFactories } from "../../selectors/factory.selector";
import { selectFactoryDropdownData } from "../../selectors/factory.selector";
import { selectSupplierDropdownData } from "../../selectors/supplier.selector";
import { selectOrganisationId } from "../../selectors/login.selector";
import QuestionnaireDetailView from "./questionnaireDetails.component";
import moment from 'moment';

const QuestionnaireTable = () => {
  const dispatch = useDispatch();
  const saqFactories = useSelector(selectSAQFactories) || [];
  const factoryDropdownData = useSelector(selectFactoryDropdownData) || [];
  const supplierDropdownData = useSelector(selectSupplierDropdownData) || [];


  const organisationId = useSelector(selectOrganisationId);
  const [selectedSAQ, setSelectedSAQ] = useState(null);

  const loading = useSelector(state => state.factory.loading);

  useEffect(() => {
    dispatch(fetchSAQFactories());
    dispatch(getListOfAllFactories({ limit: 10, offset: 0, page: 1, search: '', supplierId: null, supplierFactoryList: false }));
    dispatch(getListOfAllSuppliers({ page: 1, limit: 10, offset: 0, organisationId }));
  }, [dispatch, loading]);

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Time Received',
      width: 250,
      renderCell: (params) => {
        // Use moment to format the date
        return moment(params.value).format('MMM DD, YYYY, h:mm A'); // e.g., "Oct 16, 2024"
      },
    },
    { field: 'name', headerName: 'Facility Name', width: 200 },  // Increased column width
    { field: 'supplierName', headerName: 'Supplier Name', width: 150 }, 
    { field: 'country', headerName: 'Facility Country', width: 150 }, 
    { field: 'tier', headerName: 'Facility Tier', width: 120 }, 
    {
      field: 'assignedToFacility',
      headerName: 'Assigned to Facility',
      width: 220,
      renderCell: (params) => {
        // Find the factory in factoryDropdownData by factory ID
        const assignedFactory = factoryDropdownData.find(
          (factory) => factory.factoryId === params.value
        );
        // Construct the link if the factory exists
        if (assignedFactory) {
          const supplierId = assignedFactory.supplierId;  // Ensure you have access to the supplierId
          const factoryId = assignedFactory.factoryId;
          const link = `/suppliers/${supplierId}/factory/${factoryId}`;

          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: '#4C5ED9', textDecoration: 'none', fontWeight: 'bold' }}>
                {assignedFactory.name}
              </a>
              <img
                style={{ paddingLeft: 8 }}
                src="/ne_arrow.svg"
                alt="arrow icon"
              />
            </Box>
          );
        }
  
        return '-';
      },
    },
    {
      field: 'saqContent',
      headerName: 'Presourcing Form Content',
      width: 220,  // Increased column width
      renderCell: (params) => (
        <Button 
          variant="contained"
          sx={{
            backgroundColor: "#4C5ED9",  // Button color
            color: "white",
            borderRadius: "8px",
            padding: "8px 16px",  // Adjusted padding for more height
            fontWeight: 500,
            fontSize: "0.875rem",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#3B4BBA", // Subtle hover effect
            },
          }}
          onClick={() => {
            const fullFactoryData = saqFactories.find(factory => factory.factoryId === params.row.id);
            setSelectedSAQ(fullFactoryData);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const rows = saqFactories.map((factory) => ({
    id: factory.factoryId,
    createdAt: factory.createdAt,
    name: factory.name,
    country: factory.metadata?.country,
    supplierName: factory.metadata?.supplierName,
    tier: factory.metadata?.tier,
    assignedToFacility: factory.assignedToFactory, 
    saqContent: '',
  }));

  console.log("ROWS", rows);

  const copyToClipboard = () => {
    navigator.clipboard.writeText("https://q-auditsense-1086719075712.us-central1.run.app/" + organisationId)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Box sx={{ p: 3, margin: '0 auto' }}>  {/* Centered the table and limited maxWidth */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6" sx={{ fontSize: 24, fontWeight: 600, color: "#101828" }}>
                Presourcing Form Responses
            </Typography>
            <Tooltip title="The pre-sourcing form is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
                <Chip 
                    label="Beta" 
                    size="small"
                    sx={{
                        backgroundColor: '#4C5ED9',
                        color: 'white',
                        fontWeight: 500,
                        fontSize: '12px',
                        height: '24px'
                    }}
                />
            </Tooltip>
        </Box>
        <Button
          variant="outlined"
          onClick={copyToClipboard}
          sx={{
            color: "#6172F3",
            borderColor: "#6172F3",
            "&:hover": {
              color: "white",
              backgroundColor: "#5667e2",
            },
            textTransform: "none",
            width: "180px",
            height: "40px",
            borderRadius: "8px",
            fontWeight: 500,
            mr: "48px",
          }}
        >
          Presourcing Form
          <img
                    style={{ paddingLeft: 8 }}
                    src="/ne_arrow.svg"
                    alt="arrow icon"
                  />
        </Button>
      </Box>
      <Typography
        variant="body2"
        sx={{ fontSize: 14, color: "#667085", fontWeight: 400, mb: 2 }}
      >
        Review Presourcing Form responses here
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ height: 500, width: '100%' }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pageSize={5}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F9FAFB", // Light gray for header background
              color: "#667085", // Text color for header
              fontWeight: "800", // Bold text
              fontSize: "0.875rem",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "0.875rem",
              color: "#101828",
            },
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          }}
          components={{ Toolbar: GridToolbar }}
          disableSelectionOnClick
          sortModel={[
            {
              field: 'createdAt', // Use the field that represents "Time Received"
              sort: 'asc', // or 'desc' for descending order
            },
          ]}
        />
      </Box>
      {selectedSAQ && (
        <QuestionnaireDetailView
          open={Boolean(selectedSAQ)}
          onClose={() => setSelectedSAQ(null)}
          saqData={selectedSAQ}
          factoryDropdownData={factoryDropdownData}
          supplierDropdownData={supplierDropdownData}
          organisationId={organisationId}
        />
      )}
    </Box>
  );
};

export default QuestionnaireTable;