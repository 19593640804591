import React from 'react';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  Link,
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CloudUpload } from "@mui/icons-material";
import moment from 'moment';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import { getTooltipContent } from '../utils/lib';
import { handleWriteValue } from '../utils/lib';

// Assume DocumentUpload is still in the same directory as original, adjust imports as needed
import DocumentUpload from "./FacilityDetails/facilityDocumentDrop.component";

const renderFormFields = ({
    field,
    value,
    handleChange,
    formData, // pass the entire formData object here
    signedUrls,
    dispatch,
    handleFileClick,
    languages,
    factoryData,
    uploadDialogOpen,
    setUploadDialogOpen
  }) => {    
    if (field.dbMapping === 'irss.percentByFacility') {
        // Retrieve percentByCustomer from formData
        const percentByCustomer = parseFloat(_.get(formData, 'irss.percentByCustomer', '0'));
        if (!isNaN(percentByCustomer)) {
          // Assume percentByCustomer is a decimal like 0.3 for 30%
          // If you're using full percentage points (e.g. 30.0 for 30%), adjust accordingly:
          // e.g. use `(100 - percentByCustomer)` if dealing with whole percentages.
          value = (100 - percentByCustomer).toString();
        } else {
          value = '-';
        }
    }
    switch (field.fieldType) {            
        case 'string':
            return (
            <TextField
                fullWidth
                size="small"
                label={field.fieldLabel}
                name={field.dbMapping}
                value={value !== undefined && value !== null ? value : ''}
                onChange={(e) => handleWriteValue(handleChange, field.dbMapping, e.target.value)}
                margin="dense"
                variant="outlined"
            />
            );
        case 'dropdown':
            let dropdownValue = value !== undefined ? value : null;
            if (Array.isArray(dropdownValue) && dropdownValue.length === 1) {
            dropdownValue = dropdownValue[0];
            }
            return (
            <FormControl fullWidth size="small" margin="dense">
                <Autocomplete
                    freeSolo
                    options={field.dropdownOptions.filter((option) => typeof option === 'string')}
                    value={dropdownValue}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : '')}
                    onChange={(event, newValue) => {
                        handleWriteValue(handleChange, field.dbMapping, newValue || null);
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                        // This handles the case when user types and doesn't select from dropdown
                        if (reason === 'input') {
                            handleWriteValue(handleChange, field.dbMapping, newInputValue || null);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={field.fieldLabel}
                            variant="outlined"
                            size="small"
                            fullWidth
                            margin="dense"
                        />
                    )}
            />
                </FormControl>
            );
        case 'dropdown multi':
            let arrayValue;
            if (Array.isArray(value)) {
                arrayValue = value.filter((v) => typeof v === 'string');
            } else if (typeof value === 'string') {
                arrayValue = value.split(',').map(v => v.trim()).filter(Boolean);
            } else {
                arrayValue = [];
            }
            return (
                <FormControl fullWidth size="small" margin="dense">
                    <Autocomplete
                multiple
                freeSolo
                options={field.dropdownOptions}
                value={arrayValue}
                onChange={(event, newValue) => {
                    handleWriteValue(handleChange, field.dbMapping, newValue || null);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={field.fieldLabel}
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="dense"
                    />
                )}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={option}
                            {...getTagProps({ index })}
                            key={option}
                        />
                    ))
                }
            />
                </FormControl>
            );
        case 'notes':
            return (
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    size="small"
                    label={field.fieldLabel}
                    name={field.dbMapping}
                    value={value !== undefined && value !== null ? value : ''}
                    onChange={(e) => handleWriteValue(handleChange, field.dbMapping, e.target.value)}
                    margin="dense"
                    variant="outlined"
                />
            );
        case 'bool':
            return (
            <FormControlLabel
                control={
                <Checkbox
                    checked={value === true}
                    onChange={(e) => handleWriteValue(handleChange, field.dbMapping, e.target.checked)}
                    name={field.dbMapping}
                    sx={{ pt: 2 }}
                />
                }
                label={field.fieldLabel}
            />
            );
        case 'date':
          return (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                      label={field.fieldLabel}
                      value={value ? moment.utc(value) : null}
                      sx={{ width: "100% " }}
                      onChange={(newValue) => {
                          // Format the date to ISO string, but only keep the date part
                          const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : null;
                          handleWriteValue(handleChange, field.dbMapping, formattedDate);
                      }}
                      textField={(params) => (
                          <TextField
                              {...params}
                              size="small"
                              margin="dense"
                              fullWidth
                              sx={{
                                  '& .MuiInputBase-root': {
                                      height: '40px'
                                  }
                              }}
                          />
                      )}
                  />
              </LocalizationProvider>
          );  
        case 'date-month':
            return (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        views={['year', 'month']}
                        label={field.fieldLabel}
                        value={value ? moment.utc(value) : null}
                        onChange={(newValue) => {
                            const formattedDate = newValue ? newValue.format('YYYY-MM') : null;
                            handleWriteValue(handleChange, field.dbMapping, formattedDate);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                margin="dense"
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '40px'
                                    }
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            );
        case 'number':
            return (
                <TextField
                fullWidth
                size="small"
                label={field.fieldLabel}
                name={field.dbMapping}
                value={value !== undefined && value !== null ? value : ''}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    const parsedValue = inputValue !== '' ? Number(inputValue) : null;
                    handleWriteValue(handleChange, field.dbMapping, parsedValue);
                    }}
                margin="dense"
                variant="outlined"
                type="number"
                />
            );
        case 'read-only':
            let fieldComponent = (
                <TextField
                    fullWidth
                    size="small"
                    label={field.fieldLabel}
                    value={value}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        sx: {
                            cursor: 'default',
                            '&:hover': {
                                cursor: 'default',
                                backgroundColor: 'transparent'
                            },
                            '& .MuiInputBase-input': {
                                cursor: 'default',
                                '&:focus': {
                                    backgroundColor: 'transparent',
                                    cursor: 'default'
                                }
                            },
                            '& fieldset': {
                                borderColor: '#e0e0e0 !important',
                                '&:hover': {
                                    borderColor: '#e0e0e0 !important'
                                }
                            }
                        }
                    }}
                    sx={{
                        pointerEvents: 'none',
                        backgroundColor: 'transparent',
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: '#e0e0e0'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#e0e0e0'
                            }
                        }
                    }}
                    margin="dense"
                    variant="outlined"
                />
            );
            // Check for tooltip
            if (field.tooltip?.enabled) {
                const tooltipHtml = getTooltipContent(field, formData);
                if (tooltipHtml) {
                fieldComponent = (
                    <Tooltip
                    title={<div dangerouslySetInnerHTML={{ __html: tooltipHtml }} />}
                    placement="top-start"
                    >
                    <span>{fieldComponent}</span>
                    </Tooltip>
                );
                }
            }

            return fieldComponent;
            case 'file':
              console.log("formData", formData);
              // Determine the effective database mapping key
              const effectiveDbMapping = Array.isArray(field.dbMapping)
              ? field.dbMapping.find(key => {
                  const value = _.get(formData, key);
                  return value !== null && value !== undefined;
                }) || field.dbMapping[0]
              : field.dbMapping;
              console.log("effectiveDbMapping", effectiveDbMapping);
                // Use `value` for current files:
                const currentFiles = Array.isArray(value) ? value : value ? [value] : [];
                const handleUploadSuccess = (uploadedFiles) => {
                  const updatedFiles = [...currentFiles, ...uploadedFiles];
                  handleWriteValue(handleChange, effectiveDbMapping, updatedFiles);
                };
          
                const renderFileLink = (file) => {
                  const truncateText = (text, maxLength) =>
                    text.length <= maxLength ? text : text.substring(0, maxLength) + '...';
          
                  return (
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        handleFileClick(file.key);
                      }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                      key={file.key}
                      sx={{
                        textDecoration: 'none',
                        color: 'primary.main',
                        '&:hover': {
                          textDecoration: 'underline'
                        },
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                        fontSize: 'body1.fontSize',
                        fontFamily: 'body1.fontFamily'
                      }}
                      title={file.name}
                    >
                      {file.name ? truncateText(file.name, 25) : 'View File'}
                    </Link>
                  );
                };

                // const effectiveDbMapping = Array.isArray(field.dbMapping)
                //     ? field.dbMapping[0]
                //     : field.dbMapping;
          
                return (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                    <FormControl fullWidth size="small" margin="dense">
                      <InputLabel
                        shrink
                        sx={{
                          backgroundColor: 'white',
                          px: 0.5
                        }}
                      >
                        {field.fieldLabel}
                      </InputLabel>
                      <Box
                        component="div"
                        sx={{
                          border: '1px solid #e0e0e0',
                          borderRadius: 1,
                          p: 1.5,
                          minHeight: '40px',
                          backgroundColor: '#fff',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1
                          }}
                        >{currentFiles.length > 0 ? (
                          currentFiles.map(renderFileLink)
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'text.secondary',
                              fontStyle: 'italic'
                            }}
                          >
                            No file uploaded
                          </Typography>
                        )}
                        </Box>
                      </Box>
                    </FormControl>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ mt: 2, minWidth: 'auto', p: 1 }}
                      onClick={() => setUploadDialogOpen({ open: true, fieldKey: field.dbMapping })}
                    >
                      <CloudUpload />
                    </Button>
                    <Dialog
                      open={uploadDialogOpen.open && uploadDialogOpen.fieldKey === field.dbMapping}
                      onClose={() => setUploadDialogOpen({ open: false, fieldKey: null })}
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogTitle>Upload Document</DialogTitle>
                      <DialogContent>
                        <DocumentUpload
                          field={field.fieldLabel}
                          fieldKey={effectiveDbMapping}
                          organisationId={factoryData?.organisationId}
                          supplierId={factoryData?.supplierId}
                          facilityId={factoryData?._id}
                          onClose={() => setUploadDialogOpen({ open: false, fieldKey: null })}
                          onUploadSuccess={handleUploadSuccess}
                        />
                      </DialogContent>
                    </Dialog>
                  </Box>
                );
    //   case 'file':
    //       const handleUploadSuccess = (uploadedFiles) => {
    //           console.log('Upload successful:', uploadedFiles);
    //           // Update formData immediately
    //           const currentFiles = getValue(formData, field.dbMapping) || [];
    //           handleChange(field.dbMapping, 
    //               Array.isArray(currentFiles) 
    //                   ? [...currentFiles, ...uploadedFiles]
    //                   : uploadedFiles
    //           );
    //       };
          
    //       const renderFileLink = (file) => {
    //           const handleClick = (e) => {
    //               e.preventDefault();
    //               if (signedUrls[file.key]) {
    //                   window.open(signedUrls[file.key], '_blank');
    //               } else {
    //                   dispatch({ type: 'factory/getSignedUrl', payload: { key: file.key } });
    //               }
    //           };

    //           const truncateText = (text, maxLength) => {
    //               if (text.length <= maxLength) return text;
    //               return text.substring(0, maxLength) + '...';
    //           };
          
    //           return (
    //               <Link
    //                   onClick={(e) => {
    //                       e.preventDefault();
    //                       handleFileClick(file.key);
    //                   }}
    //                   //href={file.url}
    //                   href="#"
    //                   target="_blank"
    //                   rel="noopener noreferrer"
    //                   key={file.key}
    //                   sx={{
    //                       textDecoration: 'none',
    //                       color: 'primary.main',
    //                       '&:hover': {
    //                           textDecoration: 'underline'
    //                       },
    //                       display: 'block',
    //                       overflow: 'hidden',
    //                       textOverflow: 'ellipsis',
    //                       whiteSpace: 'nowrap',
    //                       maxWidth: '100%',
    //                       fontSize: 'body1.fontSize',
    //                       fontFamily: 'body1.fontFamily'
    //                   }}
    //                   title={file.name} // Show full name on hover
    //               >
    //                   {file.name ? truncateText(file.name, 25) : 'View File'}
    //               </Link>
    //           );
    //       };      
    //       const currentFiles = getValue(factoryData, field.dbMapping);
    //       return (
    //           <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
    //           <FormControl fullWidth size="small" margin="dense">
    //               <InputLabel 
    //                   shrink 
    //                   sx={{ 
    //                       backgroundColor: 'white',
    //                       px: 0.5
    //                   }}
    //               >
    //               {field.fieldLabel}
    //               </InputLabel>
    //               <Box
    //               component="div"
    //                   sx={{
    //                       border: '1px solid #e0e0e0',
    //                       borderRadius: 1,
    //                       p: 1.5,
    //                       minHeight: '40px',
    //                       backgroundColor: '#fff',
    //                       display: 'flex',
    //                       flexDirection: 'column',
    //                       gap: 1
    //                   }}
    //                   >
    //               <Box 
    //                   component="div"
    //                   sx={{ 
    //                       display: 'flex', 
    //                       flexDirection: 'column', 
    //                       gap: 1 
    //                   }}
    //               >{currentFiles ? (
    //                   Array.isArray(currentFiles) ? (
    //                       currentFiles.map(renderFileLink)
    //                   ) : typeof currentFiles === 'string' ? (
    //                       renderFileLink({ url: currentFiles, name: 'View File' })
    //                   ) : (
    //                       renderFileLink(currentFiles)
    //                   )
    //               ) : (
    //                   <Typography 
    //                       variant="body2" 
    //                       sx={{ 
    //                           color: 'text.secondary',
    //                           fontStyle: 'italic'
    //                       }}
    //                   >
    //                       No file uploaded
    //                   </Typography>
    //               )}
    //               </Box>
    //           </Box>
    //       </FormControl>
    //       <Button
    //           variant="outlined"
    //           size="small"
    //           sx={{ mt: 2, minWidth: 'auto', p: 1 }}
    //           onClick={() => setUploadDialogOpen({ open: true, fieldKey: field.dbMapping })}
    //       >
    //           <CloudUpload />
    //       </Button>
    //       {/* Add Dialog for DocumentUpload */}
    //       <Dialog 
    //           open={uploadDialogOpen.open && uploadDialogOpen.fieldKey === field.dbMapping} 
    //           onClose={() => setUploadDialogOpen({ open: false, fieldKey: null })}
    //           maxWidth="md"
    //           fullWidth
    //       >
    //           <DialogTitle>Upload Document</DialogTitle>
    //           <DialogContent>
    //               <DocumentUpload 
    //                   field={field.fieldLabel}
    //                   fieldKey={field.dbMapping}
    //                   organisationId={factoryData.organisationId}
    //                   supplierId={factoryData.supplierId}
    //                   facilityId={factoryData._id}
    //                   onClose={() => setUploadDialogOpen({ open: false, fieldKey: null })}
    //                   onUploadSuccess={handleUploadSuccess}
    //               />
    //           </DialogContent>
    //       </Dialog>
    //   </Box>
    //       );
        case 'none':
            return null; // This will create empty spaces
        default:
            return null;
        }
    };

export default renderFormFields;