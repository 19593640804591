import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectActionNeededData,
  selectFactoryData,
} from "../../selectors/factory.selector";
import { selectAuditListForFactory, selectCapListForFactory } from "../../selectors/audit.selector";

import FactoryActionNeeded from "./factoryActionNeeded.component";

import FactoryMostRecentAuditDetails from "./factoryMostRecentAuditDetails.component";

const FacilityComplianceStatus = () => {

  const params = useParams();
  const selectFactory = useSelector(selectFactoryData);

  const actioNeededData = useSelector(selectActionNeededData);

  const factoryCapData = useSelector((state) =>
    selectCapListForFactory(state, params.factoryId)
  );

  // Filter out items with status "Open"
  const openCapData = factoryCapData.filter(item => item.status === "Open");

  // Check if the audit is processed
  //const auditIsProcessed = selectFactory?.latestAudit?.metadata?.severityCategories ? true : false;

  return (
    <>
    {/* Render FactoryMostRecentAuditDetails if the latest audit is active */}
    {/* {selectFactory?.latestAudit?.state === "active" && auditIsProcessed && (
      <FactoryMostRecentAuditDetails selectedFactory={selectFactory} />
    )} */}

    {/* Render FactoryActionNeeded if overallTriggered is true */}
    {actioNeededData.triggers.overallTriggered && (
      <Grid item>
        <FactoryActionNeeded
          capDetails={openCapData}
          certificateDetails={actioNeededData.certificateDetails}
          rating={actioNeededData.rating}
          irssDetails={actioNeededData.irssDetails}
          upcomingAuditDate={actioNeededData.upcomingAuditDate}
          nextAssessmentWindowDateStart={actioNeededData.nextAssessmentWindowDateStart}
          nextAssessmentWindowDateEnd={actioNeededData.nextAssessmentWindowDateEnd}
          triggersObject={actioNeededData.triggers}
          auditNotConfirmedMessage={actioNeededData.auditNotConfirmedMessage}
          factoryId={params.factoryId}
          supplierId={params.supplierId}
        />
      </Grid>
    )}

    {/* Fallback message if no active audit and no action needed */}
    {selectFactory?.latestAudit?.state !== "active" && !actioNeededData.triggers.overallTriggered && (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 16,
            color: "#8B8D97",
          }}
        >
          No audit exist for facility status. No action alerts.
        </Typography>
      </Box>
    )}
      {/* {selectFactory?.latestAudit?.state === "active" ? (
        <FactoryMostRecentAuditDetails selectedFactory={selectFactory} />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}
          >
            No audit exist for facility status
          </Typography>
        </Box>
      )}

      <Grid item>
        {openCapData?.length > 0 && (
          <FactoryActionNeeded 
          capDetails={openCapData} 
          certificateDetails={actioNeededData.certificateDetails} 
          rating={actioNeededData.rating}
          irssDetails = {actioNeededData.irssDetails}
          nextAuditDate={actioNeededData.nextAuditDate}
          triggersObject={actioNeededData.triggers}
          factoryId={params.factoryId}
          supplierId={params.supplierId} />
        )}
      </Grid> */}
    </>
  );
};

export default FacilityComplianceStatus;
