import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  Skeleton,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import { debounce, set } from "lodash";
import moment from "moment";
import TopBreadcrumbs from "./topBreadcrumbs.component";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getFactoryById,
  getListOfAllFactories,
  getSearchResultOfFactories,
} from "../reducers/factory.reducer";
import { selectOrganisationId } from "../selectors/login.selector";
import {
  selectFactoryCache,
  selectFactoryList,
  selectSearchCount,
  selectSearchResultsFactoryList,
  selectTotalCount,
  selectActionNeededData,
  selectActionNeededDataForFactories
} from "../selectors/factory.selector";
import { selectLoadingState } from "../selectors/misc.selector";
import { AuditStyles } from "../styles";
import CreateFacilityForm from "./createFacility.component";

const createFilterModelStore = () => {
  let listeners = [];
  const lsKey = 'factoryGridFilterModel';
  const emptyModel = 'null';

  return {
    subscribe: (callback) => {
      listeners.push(callback);
      return () => {
        listeners = listeners.filter((listener) => listener !== callback);
      };
    },
    getSnapshot: () => {
      try {
        return localStorage.getItem(lsKey) || emptyModel;
      } catch (error) {
        return emptyModel;
      }
    },
    getServerSnapshot: () => {
      return emptyModel;
    },
    update: (filterModel) => {
      localStorage.setItem(lsKey, JSON.stringify(filterModel));
      listeners.forEach((listener) => listener());
    },
  };
};

const usePersistedFilterModel = () => {
  const [filterModelStore] = React.useState(createFilterModelStore);

  const filterModelString = React.useSyncExternalStore(
    filterModelStore.subscribe,
    filterModelStore.getSnapshot,
    filterModelStore.getServerSnapshot,
  );

  const filterModel = React.useMemo(() => {
    try {
      return JSON.parse(filterModelString) || undefined;
    } catch (error) {
      return undefined;
    }
  }, [filterModelString]);

  return React.useMemo(
    () => [filterModel, filterModelStore.update],
    [filterModel, filterModelStore.update],
  );
};

const FactoryTableDataGrid = () => {
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);
  const isLoading = useSelector(selectLoadingState);
  const totalCount = useSelector(selectTotalCount);

  // console.log("factory list", factoriesList)
  

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    dispatch(getSupplierById(supplierId));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };

  //const factoriesWithActionNeededData = useSelector(selectActionNeededDataForFactories);

  // Helper function to get action needed data for a factory
  const getActionNeededData = (factory) => {
    return selectActionNeededData.resultFunc({ factoryData: factory });
  };

  const rows = factoriesList
    .filter(factory => {
      return (
        !factory?.tier?.toLowerCase().includes('agent') 
      );
    })
    .map((factory) => {
      const actionNeededData = getActionNeededData(factory);
      const actionAlertTrigger = actionNeededData.triggers?.overallTriggered ? "Yes" : "No";

      return {
        id: factory?.factoryId,
        factoryName: factory?.name,
        factoryIdFromMetadata: factory?.factoryIdFromMetadata || "-",
        country: factory?.location,
        tier: factory?.tier || "-",
        associatedSupplier: factory?.supplierName || "-",
        actionAlert: actionAlertTrigger,
        supplierId: factory?.supplierId,
        vendorRating: factory?.metadata?.vendorRating || "-",
        complianceStatus: factory?.metadata?.complianceStatus || "-",
        lastAuditDate: factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate ? moment(factory.metadata.socialAssessmentInfo.lastAssessmentDate).format("MMM. DD, YYYY") : "-",
        productionStatus: factory?.metadata?.supplyChainStatus || "-",
        confirmedNextAuditDate: factory?.metadata?.socialAssessmentInfo?.confirmedAuditDate ? moment(factory.metadata.socialAssessmentInfo.confirmedAuditDate).format("MMM. DD, YYYY") : "-",
      };
    });

  const columns = [
    { 
      field: "factoryName", 
      headerName: "Facility Name", 
      minWidth: 180,
      flex: 1,
      resizable: true,
    },
    {
      field: "associatedSupplier",
      headerName: "Supplier Name",
      minWidth: 160,
      flex: 1,
      resizable: true,
    },
    {
      field: "tier",
      headerName: "Tier",
      type: "number",
      minWidth: 80,
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      resizable: true,
    },
    {
      field: "productionStatus",
      headerName: "Production Status",
      minWidth: 140,
      flex: 1,
      resizable: true,
    },
    { 
      field: "complianceStatus", 
      headerName: "Compliance Status", 
      minWidth: 140,
      flex: 1,
      resizable: true,
    },
    {
      field: "vendorRating",
      headerName: "Rating",
      minWidth: 120,
      flex: 0.8,
      resizable: true,
      renderCell: (params) => {
        const rating = params.value?.toLowerCase();
        let backgroundColor;
        if (rating.includes("green")) {
          backgroundColor = AuditStyles.auditRatingColors.green;
        } else if (rating.includes("yellow")) {
          backgroundColor = AuditStyles.auditRatingColors.yellow;
        } else if (rating.includes("red")) {
          backgroundColor = AuditStyles.auditRatingColors.red;
        } else if (rating.includes("orange")) {
          backgroundColor = AuditStyles.auditRatingColors.orange;
        } else {
          backgroundColor = "transparent";
        }
        return (
          <Box
            sx={{
              display: "inline-block",
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: backgroundColor,
              color: "black",
            }}
          >
            {params.value || "-"}
          </Box>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      minWidth: 120,
      flex: 0.8,
      resizable: true,
    },
    {
      field: "lastAuditDate",
      headerName: "Last Audit Date",
      minWidth: 140,
      flex: 1,
      resizable: true,
    },
    {
      field: "confirmedNextAuditDate",
      headerName: "Confirmed Next Audit Date",
      minWidth: 140,
      flex: 1,
      resizable: true,
    },
    {
      field: "actionAlert",
      headerName: "Action Items",
      minWidth: 120,
      flex: 0.8,
      resizable: true,
      renderCell: (params) => {
        // Conditionally render Chip if value is "Yes" or "No"
        if (params.value === "Yes" || params.value === "No") {
          return (
            <Chip
              label={params.value}
              sx={{
                borderRadius: "16px",
                fontWeight: 500,
                fontSize: 12,
                backgroundColor: params.value === "Yes" ? "#FECDCA" : "#F2F4F7",
                color: params.value === "Yes" ? "#B42318" : "default",
              }}
            />
          );
        } else {
          // Render plain text for other values
          return <span>{params.value}</span>;
        }
      },
    },
    {
      field: ".",
      headerName: "",
      minWidth: 100,
      flex: 0.5,
      resizable: true,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            // console.log("params.row.supplierId", params.row);
            navigateToFactoryDetails(params.row.supplierId, params.row.id);
          }}
          sx={{
            backgroundColor: "#F2F4F7",
            color: "#344054",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#E4E7EC", 
              fontWeight: "bold",
              boxShadow: "none"
            },
            textTransform: "none",
            minWidth: "79px",
            height: "40px",
            borderRadius: "8px",
          }}
          size="small"
        >
          Details
        </Button>
      ),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
    filter: {
      filterModel: {
        items: [],
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredFactories, setFilteredFactories] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const [count, setCount] = useState(totalCount);

  const factoryCache = useSelector((state) => selectFactoryCache(state, page));
  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsFactoryList);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredFactories(searchResults);
    } else {
      setFilteredFactories(factoriesList);
    }
  }, []);

  // commenting this code. This was for the old table.
  // useEffect(() => {
  //   if (factoryCache) {
  //     setFilteredFactories(factoryCache);
  //   } else {
  //     const limit = 8;
  //     const offset = (page - 1) * limit;

  //     dispatch(getListOfAllFactories({ page, limit, offset, organisationId }));
  //   }
  // }, [page, factoryCache]);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
    }
  }, [organisationId]); // Runs when organisationId changes

  const debouncedFetchFactories = useCallback(
    debounce((term) => {
      const response = dispatch(getSearchResultOfFactories({ search: term }));
      console.log(response);
    }, 100), // Delay in milliseconds
    []
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText("https://q-auditsense-1086719075712.us-central1.run.app/" + organisationId)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const location = useLocation();

  const [filterModel, setFilterModel] = usePersistedFilterModel();

  const onFilterModelChange = React.useCallback(
    (newFilterModel) => {
      setFilterModel(newFilterModel);
    },
    [setFilterModel],
  );

  return (
    <Box sx={{ p: 3 }}>
      <TopBreadcrumbs />
      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
        <div>
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
            Facilities
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your Facilities here
          </Typography>
        </div>
        <div>
          <Button
            variant="outlined"
            onClick={copyToClipboard}
            sx={{
              color: "#6172F3",
              "&:hover": {
                color: "white",
                backgroundColor: "#5667e2",
              },
              textTransform: "none",
              width: "180px",
              height: "40px",
              borderRadius: "8px",
              mr: "24px",
            }}
          >
            Presourcing Form
            <img
              style={{ paddingLeft: 8 }}
              src="/ne_arrow.svg"
              alt="arrow icon"
            />
          </Button>
          <Button
            variant="outlined"
            onClick={() => { setIsDrawerOpen(true) }}
            sx={{
              color: "#6172F3",
              "&:hover": {
                color: "white",
                backgroundColor: "#5667e2",
              },
              textTransform: "none",
              width: "200px",
              height: "40px",
              borderRadius: "8px",
              mr: "48px",
            }}
          >
            Create New Facility
          </Button>
        </div>
      </div>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
        {/* <TextField
          label="Search supplier"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="./search.svg" alt="Search" />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      <div style={{ width: "100%", padding: "24px 48px" }}>
        <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              {[...Array(10)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={60}
                  animation="wave"
                  sx={{ my: 1 }}
                />
              ))}
            </Box>
          ) : (
            <DataGridPro
              {...dataGridFormat}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              initialState={{
                pinnedColumns: {
                  left: ['factoryName'],
                  right: ['.'],
                },
                filter: {
                  filterModel: {
                    items: [
                      { field: 'complianceStatus', operator: 'equals', value: 'AUTHORIZED' },
                      { field: 'complianceStatus', operator: 'contains', value: 'PENDING AUTHORIZATION' },
                      { field: 'complianceStatus', operator: 'contains', value: 'PROBATIONARY PERIOD' },
                    ],
                    logicOperator: 'or'
                  },
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              // filterModel={filterModel}
              // onFilterModelChange={onFilterModelChange}
              getRowHeight={() => "auto"}
              sx={{
                borderRadius: "8px",
                padding: "16px",
                "& .MuiDataGrid-cell": {
                  padding: "8px",
                },
                "& .MuiDataGrid-row": {
                  maxHeight: "none !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f0f0f0", // Light gray background
                  color: "#333", // Dark text color
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeader": {
                  padding: "16px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
            />
          )}
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => { setIsDrawerOpen(false) }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "98%",
            width: "90%",
            margin: "0 auto",
            display: "flex",
            justifySelf: "center",
          },
        }}
      >
        <CreateFacilityForm handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default FactoryTableDataGrid;
