import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Box, Divider, Card, Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierData,
  selectSupplierNameFromId,
} from "../selectors/supplier.selector";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import {
  selectFacilityPageTab,
  selectFactoryData,
  selectFactoryNameFromId,
  selectFactoryTierFromId,
} from "../selectors/factory.selector";
import FacilityInformation from "../components/FacilityDetails/facilityInformation.component";
import FacilityInternalInformation from "../components/FacilityDetails/facilityInternalInformation.component";
import FacilityTabs from "../components/FacilityDetails/facilityTabs.component";
import FacilityComplianceStatus from "../components/FacilityDetails/facilityComplianceStatus.component";
import FacilityDataTab from "../components/FacilityDetails/facilityDataTab.component";
import EnvironmentalManagementDataTab from "../components/FacilityDetails/environmentalManagementDataTab.component";
import DocumentationAndCertificationDataTab from "../components/FacilityDetails/docsCertsDataTab.component";
import FacilityAuditList from "../components/FacilityDetails/facilityAuditList.component";
import FacilityInformationDrawer from "../components/FacilityDetails/facilityInformationDrawer.component";
import { getFactoryById } from "../reducers/factory.reducer";
import EditToggleSwitch from "../components/editToggleSwitch.component";
import ConfidentialBusinessInformation from "../components/confidentialBusinessInformation.component";
import AdditionalFactoryInformation from "../components/FacilityDetails/facilityAdditionalInformation.component";
import { PageStyles } from "../styles";
import {
  selectAuditList,
  selectAuditListForFactory,
  selectMostRecentAuditIdForFactory,
} from "../selectors/audit.selector";
import { getListOfAllAudits, getAuditById } from "../reducers/audit.reducer";
import { getSupplierById } from "../reducers/supplier.reducer";
import CommentSection from "../components/commentSection.component";

const FacilityDetails = () => {
  const generalInfoRef = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();

  const selectedFactoryData = useSelector(selectFactoryData);
  const supplierData = useSelector(selectSupplierData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFactoryData = async () => {
      if (!selectedFactoryData || selectedFactoryData.id !== params.factoryId) {
        setIsLoading(true);
        try {
          await dispatch(
            getFactoryById({
              supplierId: params.supplierId,
              factoryId: params.factoryId,
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchFactoryData();
  }, [params.factoryId, params.supplierId, dispatch]);

  useEffect(() => {
    if (!supplierData || supplierData?.id !== params.supplierId) {
      dispatch(getSupplierById(params.supplierId));
    }
  }, [params.supplierId, dispatch]);
  const selectedTab = useSelector(selectFacilityPageTab);

  useEffect(() => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: params.supplierId,
      })
    );
  }, []);

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {selectedFactoryData?.name}
                {selectedFactoryData?.metadata?.tier && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Tier {selectedFactoryData?.metadata?.tier.split(' ')[1]}
                  </Box>
                )}
                {selectedFactoryData?.metadata?.privateLabel === "Yes" && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Private Label
                  </Box>
                )}
              </Typography>
            </Grid>
            {/* <Grid item>
              <EditToggleSwitch />
            </Grid> */}
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md={12}>
            <div ref={generalInfoRef}>
              <FacilityInformation />
            </div>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <ConfidentialBusinessInformation
              title="Internal Status Notes"
              data={selectedFactoryData}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <Card
              sx={{
                p: 1,
                pt: 3,
                boxShadow:
                  "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                border: "1px solid rgba(234, 236, 240, 1)",
                borderRadius: "12px",
                height: '100%', // Ensure the card takes up the full height
                margin: 'auto',
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2, ml: 2, mr: 2 }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#344054",
                      display: "inline-block",
                    }}
                  >
                    Notes
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mt: 0, mr: 4 }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 400, fontSize: 16, ml: 2, mb: 1 }}>
                      Internal Status Notes
                    </Typography>
                      <CommentSection source="factory" field="internalStatusNotes" layout='topBottom' />
                    </Grid>
                    <Grid item xs={12} md={3}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 400, fontSize: 16, ml: 2,mb: 1 }}>
                        Pending Social Compliance Info
                      </Typography>
                      <CommentSection source="factory" field="pendingSocialComplianceInfo" layout='topBottom' />
                    </Grid>
                    <Grid item xs={12} md={3}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 400, fontSize: 16, ml: 2,mb: 1 }}>
                        Pending Environmental Compliance Info
                      </Typography>
                      <CommentSection source="factory" field="pendingEnvironmentalComplianceInfo" layout='topBottom' />
                    </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <AdditionalFactoryInformation />
          </Grid>
          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <FacilityTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "status" ? (
                    <FacilityComplianceStatus />
                  ) : selectedTab === "audits" ? (
                    <FacilityAuditList />
                  ) : selectedTab === "data" ? (
                    <FacilityDataTab />
                  ) : selectedTab === "environmental" ? (
                    <EnvironmentalManagementDataTab/>
                  ) : (
                    <DocumentationAndCertificationDataTab/>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FacilityDetails;
