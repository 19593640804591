import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { PageStyles } from "../../styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectAdditionalFactoryInformation } from "../../selectors/factory.selector";
import { useSelector } from "react-redux";
import { FacilityStyles } from "../../styles";

// getColor function purpose: To dynamically set the color of field labels in the facility information section based 
// on whether it is extracted from the latest audit or from the intake form.
// Logic:
// "normal": If auditExists is true, the label is colored blue; otherwise, it uses the default color. This pulls from dataCitation.
// "dec12": If auditProcessingDate is later than December 12, 2024, the label is colored blue; otherwise, it uses the default color. Note: Historical audits will be ignored as they do not have a createdAt date.
// "dec19": If auditProcessingDate is later than December 19, 2024, the label is colored blue; otherwise, it uses the default color. Note: Historical audits will be ignored as they do not have a createdAt date.
// "intake": No color change is applied; the label uses the default color.
// Usage: This function is used to style the labels of fields in the facility information display, providing visual cues based on audit data conditions.


const AdditionalFactoryInformation = () => {
  const dataMigrationMode = false;
  const [expanded, setExpanded] = React.useState(true);
  
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const { factoryInfo, auditExists, auditProcessingDate } = useSelector(selectAdditionalFactoryInformation);

  const formatValue = (value) => {
    if (Array.isArray(value)) {
      return value.join(", ");
    }
    return value;
  };

  const getColor = (color) => {
    if (!auditProcessingDate) {
      return "inherit";
    }
    const auditDate = new Date(auditProcessingDate);
    const dec12Date = new Date("2024-12-12");
    const dec19Date = new Date("2024-12-19");
    const jan6Date = new Date("2025-01-06");

    switch (color) {
      case "normal":
        return auditExists ? "#6566db" : "inherit";
      case "dec12":
        return auditExists && (auditDate > dec12Date) ? "#6566db" : "inherit";
      case "dec19":
        return auditExists && (auditDate > dec19Date) ? "#6566db" : "inherit";
      case "jan6":
          return auditExists && (auditDate > jan6Date) ? "#6566db" : "inherit";
      case "intake":
      default:
        return "inherit";
    }
  };
  
    return (
      <Card variant="outlined" sx={FacilityStyles.additionalInformationCard}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="subtitle1" fontWeight="bold">
                Additional Facility Information
              </Typography>
              <Box
                      sx={PageStyles.tierAndPrivateLabel}
                      size="small"
                      variant="outlined"
                    >
                <Typography variant="caption" color="#6566db">
                  Data from latest audit
                </Typography>
                {/* <Typography variant="caption" color="textSecondary">
                {auditExists ? "Data from latest audit" : "Data from intake form"}
                </Typography> */}
              </Box>
              <Box
                sx={PageStyles.tierAndPrivateLabel}
                size="small"
                variant="outlined"
            >
              <Typography variant="caption" color="textSecondary">
                Data from intake form
              </Typography>
            </Box>      
            </Box>      
            <Button onClick={handleToggleExpand} size="small">
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          </Box>
          {expanded && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {dataMigrationMode ? (
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    Data Migration and cleaning in progress. This section is temporarily disabled.
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ display: "inline-flex" }}
                      gap={3.5}
                    >
                      {factoryInfo.map((section, index) => (
                        <Box
                          key={index}
                          variant="outlined"
                          sx={FacilityStyles.additionalInformationSectionHeading}
                        >
                          {section.heading}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container spacing={3}>
                      {factoryInfo.map(
                        (section, sectionIndex) =>
                          section.values.map((item, itemIndex) => (
                            <Grid item xs={3} key={`${sectionIndex}-${itemIndex}`}>
                              <Typography variant="body2" color={getColor(item.color)}>
                                {item.label}
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                {formatValue(item.value)}
                              </Typography>
                            </Grid>
                          ))
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  };
  

export default AdditionalFactoryInformation;
